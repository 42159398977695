import React from 'react'
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"


const Newsletter = () => {
    const intl = useIntl()
    
    return (
        <div className="container mx-auto md:max-w-xl">
            <div className="p-10 md:py-20 md:p-0">
                <div className="p-16 bg-white rounded-4xl shadow-lg mx-auto text-center">
                    <p className="typo-xl mb-4 md:mb-8"><FormattedMessage id="newsletter.title" /></p>
                    <p className="typo-text-lg mb-8"><FormattedMessage id="newsletter.text" /></p>
                    <a href="http://eepurl.com/c7ejtv"
                        className="btn btn-black mb-4 md:mb-8"
                        target="_blank"
                        rel="noreferrer"
                        aria-label={intl.formatMessage({ id: "newsletter.button" })}>
                        <FormattedMessage id="newsletter.button" />
                    </a>
                    <p className="text-sm opacity-50"><FormattedMessage id="newsletter.description1" /><br /><FormattedMessage id="newsletter.description2" /></p>
                </div>
            </div>
        </div>
    )
}

export default Newsletter






