import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import Layout from '../components/layout'
import Newsletter from '../components/newsletter'
import SEO from "../components/seo"


if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

export default props => {
    const intl = useIntl()

    return (
        <Layout>
            <SEO
                title={intl.formatMessage({ id: "site.menu.contact" })}
            />
            <div className="header-bg header-bg-06">
                <div className="tip-container">
                    <div className="tip"><FormattedMessage id="site.clickHold" /></div>
                </div>
            </div>
            <div className="container mx-auto py-16 md:py-24 px-8">
                <div>
                    <p className="typo-xxl mb-10"><FormattedMessage id="contact.title" /></p>
                    <div className="w-2/3">
                        <p className="text-2xl"><FormattedMessage id="contact.content" /></p>
                    </div>

                </div>
            </div>

            <div className="bg-beige">
                <div className="container mx-auto py-16 md:py-24 px-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
                        <div className="col-span-1">
                            <h4 className="typo-subtitle mb-2"><FormattedMessage id="contact.general.title" /></h4>
                            <p className="typo-text-lg">
                                <a href="mailto:info@metsiktoit.ee" className="link">info@metsiktoit.ee</a>
                            </p>
                        </div>
                        <div className="col-span-1">
                            <h4 className="typo-subtitle mb-2"><FormattedMessage id="contact.collaboration.title" /></h4>
                            <p className="typo-text-lg">
                                <a href="mailto:liis@metsiktoit.ee" className="link">liis@metsiktoit.ee</a>
                            </p>
                        </div>
                        <div className="col-span-1">
                            <p className="">
                                <h4 className="typo-subtitle mb-2"><FormattedMessage id="contact.follow.title" /></h4>
                                <ul className="typo-text-xl">
                                    <li>
                                        <a
                                            href="http://eepurl.com/c7ejtv"
                                            className="link"
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label={intl.formatMessage({ id: "contact.mailinglist" })}>
                                            <FormattedMessage id="contact.mailinglist" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/metsiktoit/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="link"
                                            aria-label={intl.formatMessage({ id: "contact.instagram" })}>
                                            <FormattedMessage id="contact.instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/metsiktoit"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="link"
                                            aria-label={intl.formatMessage({ id: "contact.facebook" })}>
                                            <FormattedMessage id="contact.facebook" />
                                        </a>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="">
                <div className="container mx-auto py-16 md:py-24 px-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
                        <div className="col-span-1">
                            <h4 className="typo-subtitle mb-2"><FormattedMessage id="contact.retailers.title" /></h4>
                            <p className="mb-5"><FormattedMessage id="contact.retailers.text" /></p>
                            <p className="typo-text-lg">
                                <a href="mailto:info@metsiktoit.ee" className="link">info@metsiktoit.ee</a>
                            </p>
                        </div>
                        <div className="col-span-1">
                            <h4 className="typo-subtitle mb-2"><FormattedMessage id="contact.retailersList.title" /></h4>
                            <ul className="">
                                <li className="mb-1">
                                    <a href="https://www.estoniandesignhouse.ee/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link">
                                        <FormattedMessage id="contact.retailersList.EstonianDesignHouse" />
                                    </a>
                                </li>
                                <li className="mb-1">
                                    <a href="https://www.vabamu.ee/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link">
                                        <FormattedMessage id="contact.retailersList.Vabamu" />
                                    </a>
                                </li>
                                <li className="mb-1">
                                    <a href="https://www.loodusmuuseum.ee/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link">
                                        <FormattedMessage id="contact.retailersList.Loodusmuuseum" />
                                    </a>
                                </li>

                            </ul>
                        </div>

                    </div>
                </div>

            </div>
            <div className="bg-img-09 w-full">
                <Newsletter />
            </div>
        </Layout>
    )
}

